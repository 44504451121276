<div class="admin-container">

  <p>管理コンソールに関するヘルプです。</p>
  <br>

  <app-help-item [id]="'race'" [title]="'大会（RUNTES/e-moshicom）'"></app-help-item>
  <pre class="contents">
    RUNTESやe-moshicomと連動する、エントリーから開催日前までの練習ランキング、当日の結果管理が行える機能です。
    ここでは以下の3種類の概念を用います。
  </pre>
  <dl>
    <dt>RUNTES（代表）</dt>
    <dd>
      RUNTESから取り込んだ大会です。TotalRaceIdによって一意となり、複数大会がある場合は取り込みタイミングによって任意の1大会が選ばれます。<br/>
      なお、RUNTESからの取り込みと代表大会の決定は別処理で行っているため、RUNTESからの取り込み直後には代表大会が存在しないケースもあり得ます。この場合はしばらくお待ちください。
    </dd>
    <dt>RUNTES</dt>
    <dd>RUNTESから取り込んだ大会です。TotalRaceIdが複数あり、代表大会に選ばれなかった場合に表示されます。設定はできないため、取り込みの確認にのみ利用します。</dd>
    <dt>e-moshicom</dt>
    <dd>e-moshicomから取り込んだ大会です。</dd>
  </dl>

  <app-help-item [id]="'race-basic'" [title]="'基本設定'" [isSub]="true"></app-help-item>
  <pre class="contents">
    RUNTESやe-moshicomから取り込んだ基本情報です。
  </pre>
  <dl>
    <dt>大会名</dt>
    <dd>アプリ上で表示される大会名です。</dd>
    <dt>アクティビティ種別</dt>
    <dd>練習ランキングの対象となるアクティビティです。</dd>
    <dt>最低集計速度</dt>
    <dd>練習ランキングの対象となるアクティビティについて、指定したペース以上の場合のみ集計対象とする機能です。</dd>
    <dt>集計期間</dt>
    <dd>
      練習ランキングの集計期間です。デフォルト値は「初めてエントリーをTATTAに取り込んだ日の0時〜開催日の2日前正午」となります。
      この値を変更する場合、既に集計済みのランキングがある場合は一度削除されます。
    </dd>
    <dt>開催日</dt>
    <dd>大会の開催日です。</dd>
    <dt>開催地</dt>
    <dd>大会の開催地です。</dd>
    <dt>詳細URL</dt>
    <dd>
      RUNTES/e-moshicomの大会詳細ページURLです。
      アプリ上からこのURLを用いて詳細を確認することが可能です。
    </dd>
  </dl>

  <app-help-item [id]="'race-ranking-distance'" [title]="'アプリ版練習ランキング'" [isSub]="true"></app-help-item>
  <pre class="contents">
    アプリ上に表示される、集計期間内における合計距離によるランキングです。
    集計期間中は1時間ごとに集計がなされます。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
  </pre>

  <app-help-item [id]="'race-import-timing'" [title]="'取り込みタイミングについて'" [isSub]="true"></app-help-item>
  <pre class="contents">
    RUNTES/e-moshicomはエントリーデータを15分おきに差分出力し、TATTAはそれを即時取り込みしています。
    なお、大会マスタデータはこのエントリーデータから生成しているため、新しい大会の取り込みには1人以上エントリーしていることが必要となります。
  </pre>

  <app-help-item [id]="'race-import'" [title]="'インポート'" [isSub]="true"></app-help-item>
  <pre class="contents">
    RUNTESにおいて、Web検索が可能であり、エントリーが始まっていない、TATTAへ未取り込みの大会を直接取り込むことが可能な機能です。
    ランキング集計期間をエントリーが始まる前に変更しておきたい時などに利用します。
  </pre>

  <app-help-item [id]="'race-to-event'" [title]="'イベントに変更する'" [isSub]="true"></app-help-item>
  <pre class="contents">
    ランキング集計が始まっていない大会をイベントに変更することが可能です。
    練習ランキング以外のランキングを、アプリ内イベントではなく、RUNTES/e-moshicom経由のエントリーで利用したい場合にご利用ください。

    なおこの際、RUNTESの非代表大会が存在する場合、代表大会の移行に伴い同時い移行されます。
    一度移行した後は、追加で非代表大会を加えた場合にもイベントとして取り込まれます。

    また、RUNTES大会を移行する場合は自動的にデフォルト文言を設定できますが、RUNTESデータは15分置きに取り込みを行っているため、最大で編集後30分ほどタイムラグが発生する場合があります。

    また、もし大会が中止になり新規にイベントを開催したい場合、この機能は使わずにイベントの新規作成より、「中止大会とのエントリー連携」機能を利用してください。
  </pre>

  <hr>

  <app-help-item [id]="'event'" [title]="'イベント（RUNTES/e-moshicom/アプリ内エントリー）'"></app-help-item>
  <pre class="contents">
    実際の計測を伴わない、オンラインで行うイベントです。
    距離ランキング（「大会」で言う練習ランキング）だけでなくタイムアタックランキングなど、様々なイベントを実施することが可能です。

    基本的にはTATTAの全ユーザーに向けたアプリ内イベントとして開催するものですが、上述の「イベントに変更する」機能により、RUNTES/e-moshicomで募集することも可能です。

    なお、イベントはアプリ側では第1ソートでエントリー済みか否か（未エントリーのイベントが最上部）、第2ソートで集計期間終了日の降順で表示されます。
  </pre>

  <app-help-item [id]="'event-basic'" [title]="'基本設定'" [isSub]="true"></app-help-item>
  <pre class="contents">
    イベントの名称やルール等を設定します。
  </pre>
  <dl>
    <dt>大会名</dt>
    <dd>アプリ上で表示される大会名です。</dd>
    <dt>イベント種別</dt>
    <dd>どのようなイベントを開催するか選択します。</dd>
    <dt>アクティビティ種別</dt>
    <dd>イベント種別で指定したランキングの対象となるアクティビティです。</dd>
    <dt>最低集計速度</dt>
    <dd>ランキングの対象となるアクティビティについて、指定したペース以上の場合のみ集計対象とする機能です。</dd>
    <dt>エントリー期間</dt>
    <dd>アプリよりエントリー可能な期間です。RUNTES/e-moshicom大会をイベントに変更した場合はアプリからエントリーすることはできないため、修正できません。</dd>
    <dt>集計期間</dt>
    <dd>ランキングの集計期間です。</dd>
    <dt>サブタイトル</dt>
    <dd>イベントのサブタイトルです。</dd>
    <dt>煽り文</dt>
    <dd>ユーザーにエントリーを促す文言を入力します。</dd>
    <dt>キービジュアル</dt>
    <dd>イベントのメインとなる画像を設定します。</dd>
    <dt>イベント内容</dt>
    <dd>イベントの内容を記載します。</dd>
    <dt>イベントルール</dt>
    <dd>イベントのルールを記載します。</dd>
    <dt>イベント画像</dt>
    <dd>イベント詳細の中で表示する画像を設定できます。</dd>
    <dt>特典</dt>
    <dd>イベントの参加賞等を記載できます。</dd>
    <dt>参加資格</dt>
    <dd>イベントの参加資格を設定できます。</dd>
    <dt>中止大会とのエントリー連携</dt>
    <dd>大会が中止され、イベントに移行したい場合に利用します。<br>
      この設定により、中止大会にこれまでエントリーした参加者を新規イベントに同時に参加させることができるようになります。<br>
      設定後、中止大会にエントリーした場合にも中止大会と新イベントのそれぞれに両方エントリーしたこととして扱われます。<br>
      なお中止大会は消えずにアプリ上に表示され続けるため、大会名に中止と記載する、エントリー者限定のお知らせでその旨を通知する、ランキング集計期間を短くするといった対応を大会の方針に応じて実行してください。<br>
      <br>
      ただし、この設定の後に中止大会のTotalRaceIdへ新たな大会IDを追加しても、そのエントリーデータをイベントへ反映させることはできません。<br>
      設定時には大会IDがFIXしており、<span class="important">TATTA上に取り込まれていること</span>を確認してください（RUNTES上に大会を追加していても、誰もエントリーしていない大会はTATTAには取り込まれません）。
    </dd>
    <dt>公開/非公開</dt>
    <dd>対象のイベントを公開するかどうかを設定します。</dd>
  </dl>

  <app-help-item [id]="'event-ranking-distance'" [title]="'アプリ版距離ランキング'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「距離ランキング」とした場合のみ利用可能な、アプリ上に表示される、集計期間内における合計距離によるランキングです。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
    また、この機能ではWeb版距離ランキングと同時に集計が行われます。
  </pre>

  <app-help-item [id]="'event-ranking-distance-web'" [title]="'Web版距離ランキング'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「距離ランキング」とした場合のみ利用可能な、集計期間内における合計距離によるランキングです。
    年代別や都道府県別等、アプリ版よりも細かなランキングを出すことが可能です。
    アプリ上からはこのWeb版へのリンクが貼られますが、ここで確認したURLはそのまま外部に公開することも可能です。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
    また、この機能ではアプリ版距離ランキングと同時に集計が行われます。
  </pre>

  <app-help-item [id]="'event-ranking-time'" [title]="'タイムアタックランキング'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「タイムアタックランキング」とした場合のみ利用可能な、集計期間内に指定した距離を走った際のタイムによるランキングです。
    アプリ上からはこのWeb版へのリンクが貼られますが、ここで確認したURLはそのまま外部に公開することも可能です。

    集計期間中は5分ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    ここでは複数の「集計モード」を利用することが可能です。
  </pre>
  <dl>
    <dt>グロスタイム（サマリー）</dt>
    <dd>アクティビティのグロスタイム（一時停止した時間を含む）によりランキングを行います。</dd>
    <dt>ネットタイム（サマリー）</dt>
    <dd>アクティビティのネットタイム（一時停止した時間を含まない）によりランキングを行います。</dd>
  </dl>
  <pre class="contents">
    詳細な仕様としては以下となります。
  </pre>
  <ul>
    <li>集計期間中に行った最も速いアクティビティが対象（10km指定の大会で複数回10km以上走った場合、速い方が有効となる）</li>
    <li>手入力のアクティビティは集計対象外となる</li>
    <li>タイムは指定距離通過時の時刻をサマリーの平均ペースより計算する</li>
    <li>距離に不足がある場合、多少の距離であれば平均ペースにより時間を加算し、有効とする</li>
    <li>top10に関しては緯度経度情報から不正チェックを行う（ただしHealthKit、GoogleFit経由のデータにはラップがないためチェック不能）</li>
  </ul>
  <pre class="contents">
    なお、「手入力・再集計許可ユーザー」にTATTA ユーザーIDを指定することで、アプリのエラー等で正常に計測できなかったユーザーについて手入力の記録も集計対象とした状態で、再集計することが可能です。
  </pre>

  <app-help-item [id]="'event-ranking-team-distance'" [title]="'距離ランキング（団体戦）'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「距離ランキング（団体戦）」とした場合のみ利用可能な、アプリ上に表示される、集計期間内におけるグループでの平均距離によるランキングです。
    イベント作成者がグループを作成し、ユーザーが自分のグループを選択して参加する、という形式のイベントです。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
    また、ランキングが大きく変わる可能性もあるため十分にイベントのオーナーと調整を行ってください。

    なお、グループ別の参加者人数を集計期間前に知りたい場合、即時集計を行うことでCSVデータをDLすることが可能です。集計期間前の即時集計ではPUSH通知は送信されません。
    ただし全員が0km状態のランキングページも生成されてしまうため、CSVデータのDL後は即座に削除する例外的な運用としてください。
  </pre>

  <dl>
    <dt>最低参加人数</dt>
    <dd>指定した人数以上のエントリーがあったグループのみ集計対象とすることが可能です。</dd>
    <dt>グループ表示形式</dt>
    <dd>グループ選択を行う時等にどのような形式で表示するかを指定できます。</dd>
    <dt>グループマスタ設定</dt>
    <dd>画面の表示に従って入力してください。</dd>
    <dt>任意属性設定</dt>
    <dd>画面の表示に従って入力してください。</dd>
  </dl>

  <app-help-item [id]="'event-ranking-distance-challenge'" [title]="'距離達成チャレンジ'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「距離達成チャレンジ」とした場合のみ利用可能な、集計期間内において指定された距離を走るイベントです。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
  </pre>

  <dl>
    <dt>目標距離(km)</dt>
    <dd>このイベントの目標となる距離を指定します。</dd>
    <dt>イベントロゴ</dt>
    <dd>目標達成後に表示される完走証に表示するロゴを指定します。指定しない場合、ロゴがなくても違和感がないように完走証のデザインが変更されます。</dd>
    <dt>ベースカラー</dt>
    <dd>目標達成後に表示される完走証の色を変更することが可能です。</dd>
    <dt>.finisher連携大会ID</dt>
    <dd>
      .finisherを同時採用している大会の場合のみ、RaceAdminにて指定している大会ID（15桁）を指定してください。目標達成後、アプリから.finisherへ遷移することが可能となります。
    </dd>
  </dl>

  <app-help-item [id]="'event-ranking-area'" [title]="'ルート開拓ランキング'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「ルート開拓ランキング」とした場合のみ利用可能な、集計期間内における走破面積によるランキングです。

    面積は地図を一片約10m間隔の正方形にメッシュ化し、そのメッシュをいくつ通ったかで計算されます。
    ランキングは㎡を単位として計算しますが、この値は小数点第4位の緯度経度をベースに計算しており、緯度によって面積が異なります。
    そのため通過したメッシュの数が同じでも、面積は異なる場合があります。

    また、同じ場所を複数回通っても面積は増えません。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により面積に変動があったユーザーへはPUSH通知が届くためご注意ください。
  </pre>

  <dl>
    <dt>地域限定</dt>
    <dd>
      GeoJSONファイル（PolygonまたはMultiPolygonを含む）を指定することにより、指定した領域内を走った場合のみ面積としてカウントするモードです。
      ユーザーは合計面積に対して何%開拓したかを閲覧することも可能です。<br>
      GeoJSONファイルは<a href="https://nlftp.mlit.go.jp/index.html">国土交通省</a>等からダウンロードしたり、以下のようなサイトから作成することが可能です。<br>
      <a href="https://geojson.io/" target="_blank">https://geojson.io/</a><br>
      <br>
      また、この機能を利用する際は概要やルールに、指定エリアの詳細を記載するようにしてください（アプリの「詳細」ボタンよりエリアを確認可能である旨など）。
    </dd>
  </dl>

  <app-help-item [id]="'event-ranking-calorie-challenge'" [title]="'カロリー消費チャレンジ'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「基本設定＞イベント種別」を「カロリー消費チャレンジ」とした場合のみ利用可能な、集計期間内において指定されたKcal分を消費するイベントです。

    集計期間中は1時間ごとに集計がなされます。
    また、ランニングウォッチの利用等による記録データの取り込み遅延対応のため、集計終了日時+12時間後まで定期的に集計を行います。

    集計完了後にどうしてもランキングの修正が必要な場合、「即時集計」機能を利用することが再集計が可能ですが、再集計により距離に変動があったユーザーへはPUSH通知が届くためご注意ください。
  </pre>

  <dl>
    <dt>目標カロリー(kcal)</dt>
    <dd>このイベントの目標となる消費カロリーを指定します。</dd>
    <dt>完走証写真</dt>
    <dd>目標達成後に表示される完走証に表示する写真を指定します。</dd>
  </dl>

  <pre class="contents">
    なお、カロリーの計算式は以下の通りです。
  </pre>

  <table>
    <thead>
    <tr>
      <th>計測方法</th>
      <th>サイクリング以外</th>
      <th>サイクリング</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>TATTA</td>
      <td>体重×距離</td>
      <td>体重×距離/2</td>
    </tr>
    <tr>
      <td>AppleWatch</td>
      <td>体重×距離</td>
      <td>体重×距離/2</td>
    </tr>
    <tr>
      <td>Garmin</td>
      <td>取り込み元の値</td>
      <td>取り込み元の値</td>
    </tr>
    <tr>
      <td>NeoRun</td>
      <td>取り込み元の値</td>
      <td>取り込み元の値</td>
    </tr>
    <tr>
      <td>HealthKit</td>
      <td>取り込み元の値</td>
      <td>取り込み元の値</td>
    </tr>
    <tr>
      <td>Google Fit</td>
      <td>取り込み元の値</td>
      <td>取り込み元の値</td>
    </tr>
    </tbody>
  </table>

  <hr>

  <app-help-item [id]="'ranking'" [title]="'ランキングについて'"></app-help-item>
  <pre class="contents">
    以下では各種ランキング集計における、細かな仕様について記載しています。
  </pre>

  <app-help-item [id]="'ranking-age'" [title]="'年齢について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    ランキングに関わる年齢は全て誕生日とランキング終了日時によって計算されます。
    （ただし友だち画面等で表示される年齢は現在日時より計算したものとなります）
  </pre>

  <app-help-item [id]="'ranking-illegal'" [title]="'不正データについて'" [isSub]="true"></app-help-item>
  <pre class="contents">
    各種ランキングにおいて、以下の記録は自動的に集計から除外されます。
  </pre>
  <ul>
    <li>自転車は1000km以上、それ以外は500km以上のアクティビティ（全てのランキング）</li>
    <li>登録日時と終了日時の差分が1年以上あるアクティビティ（全てのランキング）</li>
    <li>グロスタイムが5日を越えるGarmin計測アクティビティ（全てのランキング。Garminが稀にハードウェアの不具合で未来のデータを送ってくることへの対応）</li>
    <li>アクティビティ種別がサイクリング以外で、以下のペースのアクティビティ（全てのランキング）
      <ul>
        <li>100m以上の走行でペースが1:35.8/kmより速い</li>
        <li>200m以上の走行でペースが1:35.95/kmより速い</li>
        <li>400m以上の走行でペースが1:47.575/kmより速い</li>
        <li>800m以上の走行でペースが2:06.1375/kmより速い</li>
        <li>1㎞以上の走行でペースが2:11.96/kmより速い</li>
        <li>2㎞以上の走行でペースが2:22.395/kmより速い</li>
        <li>3㎞以上の走行でペースが2:26.89/kmより速い</li>
        <li>5㎞以上の走行でペースが2:31.072/kmより速い</li>
        <li>10㎞以上の走行でペースが2:40/kmより速い</li>
        <li>15㎞以上の走行でペースが2:45/kmより速い</li>
        <li>20㎞以上の走行でペースが2:50/kmより速い</li>
        <li>30㎞以上の走行でペースが2:55/kmより速い</li>
        <li>40㎞以上の走行でペースが3:00/kmより速い</li>
        <li>100㎞以上の走行でペースが3:45/kmより速い</li>
      </ul>
    </li>
    <li>
      開始日時〜終了日時が他のアクティビティと重複しているアクティビティ（練習・距離ランキング/距離ランキング（団体戦）/距離達成チャレンジ/ルート開拓ランキング/カロリー消費チャレンジ）
    </li>
    <li>
      アクティビティ種別がサイクリング以外でラップ情報をもち、ラップのうち連続した2kmが世界記録（4分44秒79）を越えるアクティビティ（練習・距離ランキング/ルート開拓ランキングで参加者が1000人以上であれば上位100名、未満であれば上位10%、タイムアタックランキング）
    </li>
    <li>
      アクティビティ種別がサイクリング以外でTATTA計測、モーションセンサーの結果一定の割合以上乗り物に乗っているアクティビティ（練習・距離ランキング/ルート開拓ランキングで参加者が1000人以上であれば上位100名、未満であれば上位10%、タイムアタックランキング）
    </li>
  </ul>

  <hr>

  <app-help-item [id]="'coupon'" [title]="'クーポン'"></app-help-item>
  <pre class="contents">
    大会/イベントの参加者に対し、クーポンを配布することができる機能です。
    参加者全員に参加するだけでなく、合計で◯◯km以上走った際にクーポンを配布、といった条件を指定することも可能です。
  </pre>

  <dl>
    <dt>タイトル</dt>
    <dd>クーポンのタイトルです。</dd>
    <dt>説明文</dt>
    <dd>クーポンの説明文です。</dd>
    <dt>対象店舗</dt>
    <dd>クーポンを利用できる店舗について指定します。</dd>
    <dt>注意事項</dt>
    <dd>クーポンを利用する上での注意事項です。</dd>
    <dt>利用確認文</dt>
    <dd>クーポンを発行する場合の確認メッセージです。</dd>
    <dt>QRコード画像</dt>
    <dd>クーポンを発行した場合に表示される画像です。</dd>
    <dt>有効期限</dt>
    <dd>配布されたクーポンの有効期限です。これを過ぎたクーポンはアプリ上から表示されなくなります。</dd>
    <dt>配布期間</dt>
    <dd>クーポン配布を行う期間です。</dd>
    <dt>配布条件</dt>
    <dd>
      参加者が特定の行動を行った場合にクーポンを配布することが可能です。
      詳しくは <a href="./help#coupon-condition">配布条件について</a> を参照してください。
    </dd>
    <dt>利用可能時間（分）</dt>
    <dd>クーポンを発行した後、利用できる時間を指定します。店頭でQRコードを読み取るような運用で、再利用を禁止したい場合に利用できます。</dd>
    <dt>プッシュ通知</dt>
    <dd>クーポン配布時にプッシュ通知を送信するかどうかを指定します。</dd>
    <dt>プッシュ文言</dt>
    <dd>プッシュ通知を行う場合の文言を指定します。</dd>
  </dl>

  <app-help-item [id]="'coupon-condition'" [title]="'配布条件について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    配布条件はイベント種別によって、もしくはTATTAクーポン（全ユーザー向けクーポン）かどうかによって設定できる値が異なります。
  </pre>

  <h5>練習ランキング/距離ランキング</h5>
  <pre class="contents">
    「距離」を設定可能です。

    指定した距離に到達したタイミングにてクーポンを配布します。
    0kmを設定した場合、参加者全員に配布されます。
  </pre>

  <h5>タイムアタックランキング</h5>
  <pre class="contents">
    「タイム」を設定可能です。

    指定したタイム（秒）以上に速いタイムでランクインしたタイミングにてクーポンを配布します。
    例えば3kmのイベントの場合で540と設定した場合、3min/kmのペース以上で走ったユーザーが対象となります。

    0秒を設定した場合、参加者全員に配布されます。
  </pre>

  <h5>距離ランキング（団体戦）</h5>
  <pre class="contents">
    「距離」「グループ平均距離」を設定可能です。

    「距離」では指定した距離に到達したタイミングにてクーポンを配布します。
    0kmを設定した場合、参加者全員に配布されます。

    「グループ平均距離」では参加グループの平均距離が指定した距離に到達したタイミングにて、グループメンバー全員にクーポンを配布します。
    0kmを設定した場合、参加者全員に配布されます。
  </pre>

  <h5>距離達成チャレンジ</h5>
  <pre class="contents">
    「距離」を設定可能です。

    指定した距離に到達したタイミングにてクーポンを配布します。
    0kmを設定した場合、参加者全員に配布されます。
    ただし目標距離よりも長い距離を指定することはできません。
  </pre>

  <h5>ルート開拓ランキング</h5>
  <pre class="contents">
    「面積」「指定エリア到達時」を設定可能です。

    「面積」では指定した面積に到達したタイミングにてクーポンを配布します。
    0㎡を設定した場合、参加者全員に配布されます。
    なお、メッシュはおよそ1辺10mであるため、1kmの直線で100メッシュ、10,000㎡となります。
    従って、例えば「10km分、全く重複なしに走った場合を配布条件としたい」という場合は100,000㎡を設定してください。

    「指定エリア到達時」ではGeoJSONファイル（PolygonまたはMultiPolygonを含む）を指定することにより、指定した領域内を走った場合にクーポンを配布します。
    ランキングが「地域限定」の設定の場合、指定エリア到達時で指定できるエリアは大元のイベントで指定しているエリア内のみであることに注意してください（例：イベントのエリア指定が「渋谷区」の場合にクーポンの指定エリアとして「東京タワー」のエリアを指定しても機能しません）。
    ランキングが「地域限定」の設定ではない場合は、指定エリア到達時で指定できるエリアに制限はありません。

    GeoJSONファイルは<a href="https://nlftp.mlit.go.jp/index.html">国土交通省</a>等からダウンロードしたり、以下のようなサイトから作成することが可能です。
    <a href="https://geojson.io/" target="_blank">https://geojson.io/</a>
  </pre>

  <h5>カロリー消費チャレンジ</h5>
  <pre class="contents">
    「消費カロリー」を設定可能です。

    指定した消費カロリーに到達したタイミングにてクーポンを配布します。
    0kcalを設定した場合、参加者全員に配布されます。
    ただし目標消費カロリーよりも多くの値を指定することはできません。
  </pre>

  <h5>TATTAクーポン</h5>
  <pre class="contents">
    「距離」を設定可能です。

    指定した月の指定したアクティビティ種別の「統計」の合計距離が指定した距離に到達したタイミングにてクーポンを配布します。
    0kmを設定した場合、RUNNET連携済みの全TATTAユーザーに配布されます。
  </pre>

  <hr>

  <app-help-item [id]="'runner'" [title]="'ランナー検索'"></app-help-item>
  <pre class="contents">
    TATTAユーザーを検索し、「プロフィール」「アクティビティ」「エントリー履歴」を閲覧することが可能な機能です。
    問い合わせ対応等にご利用ください。
  </pre>

  <app-help-item [id]="'runner-lap'" [title]="'ラップ確認機能について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「アクティビティ」からはラップを確認可能ですが、これはサーバ側にラップを保存しているアクティビティのみが対象です。
    2.X.X系以前の旧TATTAで計測した記録や、そもそもラップ情報を持たないアクティビティでは確認できません。

    また、GarminやEpsonView/NeoRun経由のデータではラップを緯度経度から独自に再計算しています。
    そのためアプリ画面から見える値とは異なる場合があるためご留意ください。
  </pre>

  <app-help-item [id]="'runner-edit-race-result'" [title]="'大会結果の編集について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「エントリー履歴」より、大会結果を閲覧することが可能です。
    その中で2つ以上の公式大会結果を持っているデータのみ、順位やタイムを編集することができます。
    「同姓同名の別人の大会結果が入っている」という問い合わせがあった際に利用します。
    RUNNETのマイページよりユーザー自身に編集してもらう方法がベストですが、RUNNETへの問い合わせがない場合にはこちらからも対応可能です。
  </pre>

  <app-help-item [id]="'runner-illegal'" [title]="'不正ユーザーの除外について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「エントリー履歴」より、チーター（不正者）として対象ユーザーをランキングから除外することも可能です。
    また特定の大会ではなく、「アクティビティ」より指定したアクティビティの記録のみをランキングから除外することも可能です。
    集計期間終了後にこの処理を行った場合、ランキングへ反映させるためには再集計を行う必要があります（ただし表彰等を行っているイベントでは再集計により表彰対象が変わる可能性があるため、十分に注意して行ってください）。

    また明らかに不正を行っており、悪質とみられるランナーには「プロフィール＞チートチェック起動」を行うことにより、過去のアクティビティまで全て遡って、厳密な不正判定を行うことが可能です。
    この処理を行ったランナーは、今後の全てのアクティビティに対して厳密な不正チェックが行われるようになります。

    上記の不正チェックを用いても不正が横行する場合、「プロフィール＞アカウント停止」により、TATTAの利用自体を行えなくすることも可能です。
    この処理は「エントリー料金を払ったのに使えない」といったクレームを誘発する恐れがあるため、RUNNETアカウント側の削除と、本人への告知を必須とする必要があります。
    実施の際には上長との合意を得てください。
  </pre>

  <app-help-item [id]="'runner-illegal-activity'" [title]="'不正アクティビティの除外について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「アクティビティ」より、指定したアクティビティの記録のみをランキングから除外することも可能です。
    集計期間終了後にこの処理を行った場合、ランキングへ反映させるためには再集計を行う必要があります（ただし表彰等を行っているイベントでは再集計により表彰対象が変わる可能性があるため、十分に注意して行ってください）。

    なお、以下の「不正種別」にてどのような理由により不正判定されたかを確認することができます。
  </pre>
  <dl>
    <dt>JUDGED_BY_ADMIN</dt>
    <dd>TATTA ADMINより手動で設定したもの</dd>

    <dt>SUMMARY_DIST_IS_TOO_LONG</dt>
    <dd>サマリーの距離が長すぎるもの（自転車は1000km以上、それ以外は500km以上）</dd>

    <dt>SUMMARY_DIST_IS_OVERED_LAP_DIST</dt>
    <dd>サマリーの距離がラップの距離よりも長いもの（ただしトレッドミル等ではこのケースが正常であるため、通常の自動判定では不正対象としていません）</dd>

    <dt>SUMMARY_PACE_IS_OVERED_WORLD_RECORD</dt>
    <dd>サマリーのペースが世界記録を越えているもの</dd>

    <dt>LAP_PACE_IS_OVERED_WORLD_RECORD</dt>
    <dd>ラップのうち連続した2kmが世界記録を越えているもの</dd>

    <dt>INVALID_MOTION</dt>
    <dd>モーションセンサーにより、サイクリング以外のアクティビティにて一定以上の割合で乗り物に乗っていると判定されたもの</dd>

    <dt>EDITED_OLD_DATA</dt>
    <dd>アクティビティ登録日時と、アクティビティ終了日時の差分が大きいもの</dd>

    <dt>LAP_IS_SKIPPED</dt>
    <dd>1km、2kmと刻むはずのラップの途中がスキップしているもの（例： 1km、3kmで2kmが無い）</dd>

    <dt>VERY_STRICT_INVALID_MOTION</dt>
    <dd>INVALID_MOTIONを更に厳しい基準で判定したもの</dd>

    <dt>GROSS_TIME_IS_TOO_LONG</dt>
    <dd>グロスタイムが5日を越えているもの</dd>

  </dl>

  <pre class="contents">
    なお、不正判定が行われても、必ずしもその記録がアクティビティから除外されるわけではありません。
    詳しくは <a href="./help#ranking-illegal">不正データについて</a> を参照してください。
  </pre>

  <hr>

  <app-help-item [id]="'information'" [title]="'お知らせ'"></app-help-item>
  <pre class="contents">
    アプリにお知らせを表示させることができる機能です。
  </pre>
  <dl>
    <dt>タイトル</dt>
    <dd>お知らせのタイトルです。</dd>
    <dt>画像</dt>
    <dd>お知らせの詳細に表示する画像です。</dd>
    <dt>本文</dt>
    <dd>お知らせの本文です。</dd>
    <dt>配信日時</dt>
    <dd>お知らせの配信日時です。</dd>
    <dt>種別</dt>
    <dd>アプリ側に表示する、お知らせの種別を指定します。</dd>
    <dt>配信先</dt>
    <dd>全ユーザーにお知らせを表示するか、指定した大会・イベントの参加者にのみ表示するか等を指定することができます。</dd>
    <dt>プッシュ通知</dt>
    <dd>お知らせを配信する際、指定した配信先へプッシュ通知を送るかどうかを選択します。</dd>
    <dt>下書き</dt>
    <dd>下書きとして公開せずに保存しておくことが可能です。</dd>
  </dl>

  <hr>

  <app-help-item [id]="'all-user-coupon'" [title]="'TATTAクーポン'"></app-help-item>
  <pre class="contents">
    イベントに紐付かない、RUNNET連携済みの全TATTAユーザーを対象としたクーポン配布機能です。<br>毎日9時に配布条件を満たしたユーザーへ配布を開始します。この処理には時間がかかりますが、配布完了後は1時間以内にPUSH通知が行われます。<br>
    指定した年月の指定したアクティビティ種別の「統計」の距離を使用してクーポンの配布が可能です。複数アクティビティを指定した場合は指定したすべてのアクティビティの合計距離を使用します。
  </pre>
  <dl>
    <dt>タイトル</dt>
    <dd>クーポンのタイトルです。</dd>
    <dt>説明文</dt>
    <dd>クーポンの説明文です。</dd>
    <dt>対象店舗</dt>
    <dd>クーポンを利用できる店舗について指定します。</dd>
    <dt>注意事項</dt>
    <dd>クーポンを利用する上での注意事項です。</dd>
    <dt>利用確認文</dt>
    <dd>クーポンを発行する場合の確認メッセージです。</dd>
    <dt>QRコード画像</dt>
    <dd>クーポンを発行した場合に表示される画像です。</dd>
    <dt>有効期限</dt>
    <dd>配布されたクーポンの有効期限です。これを過ぎたクーポンはアプリ上から表示されなくなります。</dd>
    <dt>配布期間</dt>
    <dd>クーポン配布を行う期間です。</dd>
    <dt>アクティビティ種別</dt>
    <dd>集計対象とするアクティビティ種別を選択します。</dd>
    <dt>集計対象年月</dt>
    <dd>集計対象とする統計距離の年月を選択します。</dd>
    <dt>配布条件</dt>
    <dd>
      参加者が特定の行動を行った場合にクーポンを配布することが可能です。
      詳しくは <a href="./help#coupon-condition">配布条件について</a> を参照してください。
    </dd>
    <dt>利用可能時間（分）</dt>
    <dd>クーポンを発行した後、利用できる時間を指定します。店頭でQRコードを読み取るような運用で、再利用を禁止したい場合に利用できます。</dd>
    <dt>プッシュ通知</dt>
    <dd>クーポン配布時にプッシュ通知を送信するかどうかを指定します。</dd>
    <dt>プッシュ文言</dt>
    <dd>プッシュ通知を行う場合の文言を指定します。</dd>
  </dl>

  <hr>

  <app-help-item [id]="'team'" [title]="'チーム管理'"></app-help-item>

  <app-help-item [id]="'team-manage'" [title]="'チーム管理'" [isSub]="true"></app-help-item>
  <pre class="contents">
    チームを検索し、休止・解散・アクティブ化を行うことができます。
    各種ステータスの詳細は以下です。
  </pre>
  <dl>
    <dt>アクティブ</dt>
    <dd>
      通常のステータスです。
    </dd>
    <dt>休止中</dt>
    <dd>
      活動に制限がかかっている状態です。<br>
      過去データを閲覧することは可能ですが、ユーザーの新規参加、掲示板の投稿、イベントの作成といった操作を行うことはできません。<br>
      また、ランキングへの参加や更新も行われなくなります。
    </dd>
    <dt>解散済み</dt>
    <dd>
      解散した状態です。このステータスになると参加中のメンバーは全員、強制的に退会されます。<br>
      休止中と同じく過去データを閲覧することは可能ですが、その他一切の操作は行えなくなります。
    </dd>
    <dt>下書き（非公開）</dt>
    <dd>
      TATTA Adminからオフィシャルチーム登録をしたばかりの状態です。<br>
      TeamAdminから公開作業を行えます。
    </dd>
  </dl>

  <app-help-item [id]="'official-team-manage'" [title]="'オフィシャルチーム管理'" [isSub]="true"></app-help-item>
  <pre class="contents">
    オフィシャルチームの管理を行います。
  </pre>
  <dl>
    <dt>新規登録</dt>
    <dd>
      オフィシャルチームを新規登録します。<br>
      ここで登録した契約者メールアドレスはTeamAdminのアカウントとなり、登録と同時にパスワードが入力した宛先に送信されます。<br>
      登録時には<a href="./help#team-type">チームタイプについて</a>を必ず確認してください。
    </dd>
    <dt>既存チームの昇格</dt>
    <dd>
      既にアプリからチームを作成している場合、オフィシャルチームへ昇格させることが可能な機能です。<br>
      新規登録時と同じく、ここで登録した契約者メールアドレスはTeamAdminのアカウントとなり、登録と同時にパスワードが入力した宛先に送信されます。
      昇格時には<a href="./help#team-type">チームタイプについて</a>を必ず確認してください。
    </dd>
    <dt>ライセンス管理</dt>
    <dd>
      登録アカウントの変更、契約プランの修正/追加、過去の契約を閲覧することが可能な機能です。
    </dd>
    <dt>アカウント再発行について</dt>
    <dd>
      契約者よりログインできない等の問い合わせがあった場合、通常はTeamAdmin側で契約者本人が実行できるため、管理者が行う必要はありません。<br>
      パスワードを忘れた場合でも、パスワードリマインダーを利用することが可能です。<br>
      ただし、<span class="important">登録済みのメールアドレスが不達となり、かつパスワードを忘れた場合のみ、</span>パスワードリマインダーが使えず契約者のログインが不可となるため、その場合は「ライセンス管理」より契約者メールアドレスの変更を行うことで、アカウントの再発行を行うことが可能です。
    </dd>
    <dt>契約期間が切れた場合について</dt>
    <dd>
      オフィシャルチームはライセンスの契約期間が切れた場合、自動的に「休止中」ステータスへ移行します。<br>
      もし誤って契約が切れた場合にはライセンス管理より契約を登録し直し、「チーム管理」より再度ステータスを「アクティブ」とすることも可能です。
    </dd>
  </dl>

  <app-help-item [id]="'team-type'" [title]="'チームタイプについて'" [isSub]="true"></app-help-item>
  <pre class="contents">
    チームがどのような振る舞い、性格を持つかを規定します。現在は以下の種類に対応しています。
  </pre>
  <dl>
    <dt>一般チーム</dt>
    <dd>
      TATTAユーザーなら誰でもアプリから作成できるチームです。<br>
      定員は100名が最大で、開催できるイベント種別にも制約があります。
    </dd>
    <dt>オフィシャルチーム</dt>
    <dd>
      主にスポンサーへのセールスを前提としたチームです。<br>
      定員や開催可能なイベントを契約プランによって変更可能です。<br>
      誰でも参加可能で、アプリ上からの露出もあり、ウィジェットによってHP等の外部媒体からチームを宣伝することも可能です。<br>
      <a href="./help#team-connected-entries">エントリー自動連携</a>
      機能により、特定の大会/イベントへの参加者を自動招待することが可能となります。<br>
      <a href="./help#team-event-limited-disclosure">エントリー者限定公開</a>
      機能により、特定の大会種目/イベントへの参加者にチーム内イベントを限定公開とすることも可能です。<br>
      ただし月間ランキング等、チーム間での対抗イベントには参加できません。
    </dd>
    <dt>オフィシャルチーム（Private）</dt>
    <dd>
      主に健康経営を行なう企業など、限定されたメンバーだけでクローズドな運営を行うことを前提としたチームです。
      定員や開催可能なイベントを契約プランによって変更可能です。<br>
      招待されたメンバーしか参加できず、アプリ上から検索することはできません。<br>
      <a href="./help#team-connected-entries">エントリー自動連携</a>
      機能により、特定の大会/イベントへの参加者を自動招待することが可能となります。<br>
      <a href="./help#team-event-limited-disclosure">エントリー者限定公開</a>
      機能により、特定の大会種目/イベントへの参加者にチーム内イベントを限定公開とすることも可能です。<br>
      ただし月間ランキング等、チーム間での対抗イベントには参加できません。
    </dd>
    <dt>大会公式コミュニティ</dt>
    <dd>
      大会主催者へのセールスを前提としたチームです。<br>
      誰でもフォロー可能で、アプリ上からの露出もあり、ウィジェットによってHP等の外部媒体からチームを宣伝することも可能です。<br>
      <a href="./help#team-connected-entries">エントリー自動連携</a>
      機能により、特定の大会/イベントへの参加者を自動フォローすることが可能となります。<br>
      <a href="./help#team-event-limited-disclosure">エントリー者限定公開</a>
      機能により、特定の大会種目/イベントへの参加者にチーム内イベントを限定公開とすることも可能です。<br>
      ただし月間ランキング等、チーム間での対抗イベントには参加できません。<br>
    </dd>
  </dl>

  <app-help-item [id]="'team-required-services'" [title]="'参加資格について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    「参加資格」ではチームに参加する際、連携が必要なサービスについて指定できます。

    「利用可能なチーム内イベント参加資格」では、ここで有効としたサービスを、チーム内イベントの作成時にイベント参加に連携が必要なサービスとして都度指定できるようになります。
  </pre>

  <app-help-item [id]="'team-connected-entries'" [title]="'エントリー自動連携について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    ここで指定したランテス番号に紐づく全ての大会/特定の大会/モシコムイベントにエントリーしたことがある、もしくはこれからエントリーするTATTAユーザーに対し、そのチームへの招待（大会公式コミュニティの場合はフォロー）を自動的に送信する機能です。
    招待/フォローは以下のタイミングにて送信されます。

    - RUNNET連携済みのTATTAユーザーが新規に対象大会へエントリーした時
    - 初めてRUNNET連携した時（対象ユーザーの全てのエントリー履歴から遡及）
    - この画面にて設定を追加した時（全TATTAユーザーに対して全てのエントリー履歴から遡及）
    - ステータスを下書き/休止中からアクティブに変更した時（全TATTAユーザーに対して全てのエントリー履歴から遡及）

    なお、動作させるにはチームのステータスがアクティブである必要があります。下書き・休止中・解散済みのチームにおいては設定を行なっても招待は行われません。
  </pre>

  <app-help-item [id]="'team-event-limited-disclosure'" [title]="'エントリー者限定公開について'" [isSub]="true"></app-help-item>
  <pre class="contents">
    TeamAdminにて作成するチーム内イベントについて、RUNTES大会のエントリー種目/モシコムイベントにエントリーしたユーザーにのみ公開できる機能です。
    TeamAdmin利用者がイベントを作成した後、直接営業担当とやり取りを行って設定する運用を想定しています。

    本機能を用いることで、例えばパブリックな大会公式コミュニティやオフィシャルチームにて、RUNTES/moshicomにエントリーしたユーザーにのみ公開するチーム内イベントを作成することが可能です。
    エントリー者に限定して情報を届けたい場合、この限定公開したイベントの掲示板を利用することで参加者にのみ伝えることも可能です。

    なお対象者がチームに参加していない場合、エントリー自動連携と同様に自動招待/フォローも合わせて行われます。
  </pre>

  <h5>設定方法について</h5>
  <pre class="contents">
    TeamAdmin利用者と連絡を取り合った上で、TATTA Adminの管理者アカウントにより、TeamAdminへ代理ログインし、対象のイベント詳細画面より、「エントリー者限定公開設定」タブから実施します。
    （このタブは管理者アカウント以外には表示されないため、TeamAdmin利用者が直接設定することはできません）

    なおRUNTES設定に必要なエントリー種目ID（commendationEntryId）は、RUNNETのエントリー画面から該当種目を選択した際に表示されるURLに含まれています（以下の例であれば0001627716）。

    https://runnet.jp/runtes/user/pc/personalEntryEnqAction.do?commendationEntryId=0001627716&raceId=315833

    ただし公開前の大会やパーツ化大会ではこの値はURLに表示されないため、以下のURLにて大会IDを指定することでも確認可能です（COMMENDATION_ENTRY_IDのカラム。アクセスは社内限定）。
  </pre>
  <dl>
    <dt>本番環境</dt>
    <dd>https://shop-admin.runnet.jp/b2bReferenceCSV.do?code=6001&p1=[大会ID]</dd>
    <dt>試験環境</dt>
    <dd>https://pre3-shop-admin.runnet.jp/b2bReferenceCSV.do?code=6001&p1=[大会ID]</dd>
  </dl>

  <app-help-item [id]="'team-violation-report'" [title]="'チーム違反報告'" [isSub]="true"></app-help-item>
  <pre class="contents">
    チーム詳細画面より、違反報告がなされた場合にその報告が妥当かどうかを検証し、不適切であれば休止状態に、問題なければ無視するという運用を行うための機能です。
    休止状態のチームはメンバーの追加参加ができず、掲示板への投稿やランキングの集計対象外となる等、閲覧以外の行動ができなくなります。

    なお休止とする際、管理者へ警告メールを送り、改善の気配がなければ解散という運用を想定しています（状況に応じて変わる可能性あり）。
  </pre>

  <app-help-item [id]="'team-feed-violation-report'" [title]="'掲示板違反報告'" [isSub]="true"></app-help-item>
  <pre class="contents">
    チームの掲示板より、違反報告がなされた場合にその報告が妥当かどうかを検証し、不適切であれば非表示に、問題なければ無視するという運用を行うための機能です。
    非表示とした場合、アプリ側では該当の投稿は「規約違反のため非表示です」といった形で表示されます。
  </pre>

  <hr>

  <app-help-item [id]="'widget'" [title]="'ウィジェット管理'"></app-help-item>
  <pre class="contents">
    アプリのホーム画面に表示される各種ウィジェットについて、設定/管理可能なものを表示しています。
  </pre>

  <app-help-item [id]="'widget-poem'" [title]="'スポーツ川柳'" [isSub]="true"></app-help-item>
  <pre class="contents">
    ユーザーから投稿されたスポーツ川柳について、採用/不採用を管理できます。
    採用された川柳は、ウィジェット上で日替わりで表示されます。
  </pre>

  <hr>

  <app-help-item [id]="'user'" [title]="'ユーザー管理'"></app-help-item>
  <pre class="contents">
    TATTA Admin Consoleを利用するユーザーの登録・編集・削除等を実行可能です。
    ユーザーには以下の権限を割り振ることが可能です。
  </pre>
  <dl>
    <dt>管理者</dt>
    <dd>全ての操作を実行できる管理者権限です。</dd>
    <dt>ビューワー</dt>
    <dd>ランナー検索の実行と、大会/イベント、お知らせの閲覧を行える権限です。</dd>
    <dt>問い合わせスタッフ</dt>
    <dd>ランナー検索を実行できる権限です。</dd>
  </dl>

</div>

<div class="toc">
  <app-toc [title]="'TATTA Admin Console'" [headerSelectors]="'.admin-container h3, .admin-container h4'"
           [helpIndex]="0"></app-toc>
</div>
